import { useKeycloak } from "@react-keycloak/web";
import { useEffect, useState } from "react";
import { refreshConfig, refreshSetConfig } from "../configAPI";
import { keycloak } from "../keycloak";

export default function useInitialized() {
  const { initialized } = useKeycloak();
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    console.log("useEffect", initialized);
    if (initialized && !!keycloak.authenticated) {
      refreshConfig();
      refreshSetConfig();
      setIsInitialized(true);
      console.log("useEffect initialized", initialized);
    }
  }, [initialized]);

  return {
    isInitialized,
  };
}
