import Lottie from "lottie-react";
import groovyWalkAnimation from "../../assets/animations/loader.json";

interface LoaderProps {
  size?: string;
}

const Loader = ({ size }: LoaderProps) => {
  return (
    <div className="flex w-[100%] h-[100%] items-center justify-center z-[9999] fixed">
      <Lottie
        className={`w-[60%] lg:w-[20%]`}
        animationData={groovyWalkAnimation}
        loop={true}
      />
    </div>
  );
};

export default Loader;
