import { CustomerAndLocationDto } from "@myfamilysrl/nikita_axios_sdk";
import { nikitaAPI } from "../configAPI";

export const getCustomers = async () => {
  let customers: CustomerAndLocationDto[];

  await nikitaAPI
    .apiCustomersCustomersAndLocationsGet()
    .then((res) => {
      customers = res.data;
    })
    .catch((err) => console.warn(err));

  return customers;
};
