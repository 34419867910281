import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          // Controls default (unchecked) color for the thumb
          color: "#666",
        },
        colorPrimary: {
          "&.Mui-checked": {
            // Controls checked color for the thumb
            color: "#3e5565",
          },
        },
        track: {
          // Controls default (unchecked) color for the track
          opacity: 0.2,
          backgroundColor: "#3e5565",
          ".Mui-checked.Mui-checked + &": {
            // Controls checked color for the track
            opacity: 0.7,
            backgroundColor: "#3e5565",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#F0F0F0",
            borderRadius: "5px",
          },
          "&.Mui-selected:hover": {
            backgroundColor: "#ACB8C59E",
            borderRadius: "5px",
          },
          "&:hover": {
            backgroundColor: "#ACB8C59E",
            borderRadius: "5px",
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#3e5565",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#3e5565",
        },
      },
    },
  },
});
