import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot, RecoilEnv } from "recoil";
import App from "./App";
import { refreshConfig } from "./configAPI";
import "./index.css";
import { keycloak } from "./keycloak";
import { theme } from "./styles/overrides/override.style";
import reportWebVitals from "./reportWebVitals";

const eventLogger: any = (event: any, error: any, tokens: any) => {
  refreshConfig();
};

const tokenLogger = async (tokens: any) => {
  //console.log("token lucio ", JSON.parse(atob(tokens["token"].split(".")[1])));
  refreshConfig();
};
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;

root.render(
  <RecoilRoot>
    <QueryClientProvider client={queryClient}>
      <ReactKeycloakProvider
        authClient={keycloak}
        onEvent={eventLogger}
        onTokens={tokenLogger}
        initOptions={{
          onLoad: "login-required",
          checkLoginIframe: false,
        }}
      >
        <ThemeProvider theme={theme}>
          <StyledEngineProvider injectFirst>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </StyledEngineProvider>
        </ThemeProvider>
      </ReactKeycloakProvider>
      {/* <ReactQueryDevtools /> */}
    </QueryClientProvider>
  </RecoilRoot>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
