import { Configuration, CustomersApi } from "@myfamilysrl/nikita_axios_sdk";
import {
  EngravingStatisticsApi,
  MachineRegistrationApi,
} from "@myfamilysrl/techla_ts_sdk";
import { keycloak } from "./keycloak";

const refreshSetConfig = () => {
 
  setInterval(() => {
   
    refreshConfig()
  }, 1000)
}

let nikitaConfig: Configuration = new Configuration({
  basePath: process.env.REACT_APP_NIKITA_URL,
  accessToken: keycloak.token,
});

let config: Configuration = new Configuration({
  basePath: process.env.REACT_APP_TECHLA_URL,
  accessToken: keycloak.token,
});

const refreshConfig = () => {
  console.log('refresh token')
  nikitaConfig.accessToken = keycloak.token;
  config.accessToken = keycloak.token;
};

const nikitaAPI = new CustomersApi(nikitaConfig);
const machineAPI = new MachineRegistrationApi(config);
const statisticsAPI = new EngravingStatisticsApi(config);
const engravesByDateAPI = new EngravingStatisticsApi(config);

export {
  nikitaAPI,
  machineAPI,
  statisticsAPI,
  engravesByDateAPI,
  refreshConfig,
  refreshSetConfig
};
