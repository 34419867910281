import { Box, IconButton, Tooltip } from "@mui/material";
import { BiLogOut } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { loaderState } from "../../store/loaderState";
import { keycloak } from "../../keycloak";
import BasicBreadcrumbs from "../BreadCrumbs";

const NavBar = () => {
  const location = useLocation();
  const loader = useRecoilValue(loaderState);

  const logout = () => {
    keycloak.logout();
  };

  const handlePathName = (path: string) => {
    return (
      path.replace("/", "").charAt(0).toUpperCase() +
      path.replace("/", "").slice(1)
    );
  };

  return (
    <>
      {!loader && (
        <Box className="flex w-full items-center space-x-2">
          <Box
            className={`flex h-full w-full mt-4  items-start
      `}
          >
            <Box className="px-8">
              <BasicBreadcrumbs
                currentPath={handlePathName(location.pathname)}
              />
            </Box>
          </Box>
          <Box className="flex mt-4 items-center">
            <Box className="items-center mr-2">
              <span className="hidden md:block">
                {keycloak.authenticated ? keycloak?.idTokenParsed!.email : ""}
              </span>
            </Box>

            <Box className="bg-[#3e5565] rounded-xl">
              <Box>
                <Tooltip title="Log out">
                  <IconButton
                    sx={{ color: "whitesmoke" }}
                    aria-label=""
                    onClick={() => {
                      logout();
                    }}
                  >
                    <BiLogOut className="icon" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default NavBar;
