import Loader from "./components/Loader";
import RoutesList from "./components/RoutesList";
import useInitialized from "./hooks/useInitialized";

function App() {
  const { isInitialized } = useInitialized();
  if (isInitialized) {
    return <RoutesList />;
  }

  return <Loader />;
}
export default App;
