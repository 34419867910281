import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface BasicBreadcrumbsProps {
  currentPath: string;
}

export default function BasicBreadcrumbs({
  currentPath,
}: BasicBreadcrumbsProps) {
  // const pathPattern = new RegExp("\\/\\d+");
  // const pattern = new RegExp("/d+");
  const navigate = useNavigate();
  const [paths, setPaths] = useState<string[]>([]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    if (paths.length === 1) return;

    navigate("customers");
  };

  useEffect(() => {
    setPaths([]);
    const path = currentPath.split("/");
    path.map((item) => setPaths((prev) => [...prev, item]));
  }, [currentPath]);

  return (
    <div role="presentation" onClick={handleClick}>
      <Breadcrumbs aria-label="breadcrumb">
        {paths.length === 1 ? (
          <Typography color="text.primary">{`${currentPath}`}</Typography>
        ) : (
          <Box className="flex">
            <Link
              underline="hover"
              color="inherit"
              className="hover:cursor-pointer"
            >
              {`${paths[0]}`}
            </Link>
            <span className="px-1 ">/</span>
            <Typography color="text.primary">{paths[1]}</Typography>
          </Box>
        )}
      </Breadcrumbs>
    </div>
  );
}
