import AdfScannerOutlinedIcon from "@mui/icons-material/AdfScannerOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import { ListItem, ListItemButton } from "@mui/material";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box } from "@mui/system";
import { useEffect, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import imgMenu from "../../assets/image-menu.jpg";
import logo from "../../assets/logo2.png";
import logoXL from "../../assets/logoTec2.svg";
import { indexPathState } from "../../store/indexPathState";
import { loaderState } from "../../store/loaderState";

export const Sidebar = () => {
  // const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useRecoilState<number | null>(
    indexPathState
  );
  const location = useLocation();
  const setIndexPath = useSetRecoilState(indexPathState);
  const loader = useRecoilValue(loaderState);

  const routes = useMemo(
    () => [
      { id: 0, title: "Dashboard", icon: <InsertChartOutlinedIcon /> },
      { id: 1, title: "Customers", icon: <GroupOutlinedIcon /> },
      { id: 2, title: "Techla", icon: <AdfScannerOutlinedIcon /> },
      // { id: 3, title: "Statistics", icon: <QueryStatsOutlinedIcon /> },
    ],
    []
  );

  // const handleClick = () => {
  //   setOpen(!open);
  // };

  useEffect(() => {
    const currentPath = routes.find(
      (item) => item.title.toLowerCase() === location.pathname.replace("/", "")
    );
    if (currentPath === undefined) {
      setIndexPath(1);
    } else {
      const previousPath = currentPath.id;
      setIndexPath(previousPath);
    }
  }, [location.pathname, routes, setIndexPath]);

  const handleListItemClick = (index: number | null = 0) => {
    setSelectedIndex(index);
  };

  return (
    <>
      {!loader && (
        <Box className="hidden lg:block gap-y-8 justify-center h-screen">
          <Box
            className="group w-16 hover:w-72 bg-cover bg-local bg-no-repeat bg-center 
        px-4 pt-8 relative duration-300 justify-center h-full"
            sx={{ backgroundImage: `url(${imgMenu})` }}
          >
            <div
              className="hidden group-hover:block gap-x-4 mb-4 p-0 group-hover:p-4 
        items-center justify-center max-sm:hidden"
            >
              <img
                src={logoXL}
                width="300px"
                height="300px"
                className="text-white flex justify-center font-bold"
                alt="logo"
              />
            </div>
            <div
              className="flex gap-x-4 mb-4 p-0 group-hover:hidden group-hover:p-4 
        items-center justify-center max-sm:hidden"
            >
              <img
                src={logo}
                width="100px"
                height="100px"
                className="text-white flex justify-center font-bold"
                alt="logo"
              />
            </div>
            <Box className="flex justify-center group-hover:w-56 gap-y-10">
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className="group-hover:w-full w-32 justify-center m-0 rounded-lg"
              >
                {routes.map((route) => (
                  <ListItem key={route.id}>
                    {selectedIndex === route.id ? (
                      <ListItemButton
                        selected={selectedIndex === route.id}
                        onClick={() => handleListItemClick(route.id)}
                      >
                        <div className="flex justify-center">
                          <ListItemIcon
                            className={`
                      group-hover:min-w-[56px]
                      ${
                        selectedIndex === route.id
                          ? "text-black"
                          : "text-slate-100"
                      }`}
                            sx={{
                              minWidth: 0,
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: "5px",
                            }}
                          >
                            {route.icon}
                          </ListItemIcon>
                        </div>
                        <ListItemText
                          className={`
                  hidden group-hover:block
                  origin-left duration-400 w-32
                  ${
                    selectedIndex === route.id ? "text-black" : "text-slate-100"
                  }`}
                          primary={route.title}
                        />
                      </ListItemButton>
                    ) : (
                      <Link
                        to={
                          selectedIndex === route.id
                            ? "#"
                            : route.title.toLowerCase()
                        }
                      >
                        <ListItemButton
                          selected={selectedIndex === route.id}
                          onClick={() => handleListItemClick(route.id)}
                        >
                          <div className="flex justify-center">
                            <ListItemIcon
                              className={`
                          group-hover:min-w-[56px]
                          ${
                            selectedIndex === route.id
                              ? "text-black"
                              : "text-slate-100"
                          }`}
                              sx={{
                                minWidth: 0,
                                display: "flex",
                                justifyContent: "center",
                                borderRadius: "5px",
                              }}
                            >
                              {route.icon}
                            </ListItemIcon>
                          </div>
                          <ListItemText
                            className={`
                      hidden group-hover:block
                      origin-left duration-400 w-32
                      ${
                        selectedIndex === route.id
                          ? "text-black"
                          : "text-slate-100"
                      }`}
                            primary={route.title}
                          />
                        </ListItemButton>
                      </Link>
                    )}
                  </ListItem>
                ))}
                {/*   <ListItemButton onClick={handleClick}>
            <ListItemIcon>
              <DataSaverOffOutlinedIcon />
            </ListItemIcon>
            <ListItemText
              className="hidden group-hover:block text-slate-200 
            origin-left duration-400"
              primary="Menu"
            />
            <span
              className="hidden group-hover:block text-slate-200 
            origin-left duration-400"
            >
              {open ? <ExpandLess /> : <ExpandMore />}
            </span>
          </ListItemButton>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <Link to="statistics">
                <ListItemButton sx={{ pl: 4 }}>
                  <ListItemIcon>
                    <span
                      className="hidden group-hover:block
            origin-left duration-400"
                    >
                      <StarBorder />
                    </span>
                  </ListItemIcon>
                  <ListItemText
                    className="hidden group-hover:block text-slate-200 
                origin-left duration-400"
                    primary="SubMenu"
                  />
                </ListItemButton>
              </Link>
            </List>
          </Collapse> */}
              </List>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
