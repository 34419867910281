import AdfScannerOutlinedIcon from "@mui/icons-material/AdfScannerOutlined";
import CloseIcon from "@mui/icons-material/Close";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import MenuIcon from "@mui/icons-material/Menu";
import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Box } from "@mui/system";
import {
  createElement,
  lazy,
  LazyExoticComponent,
  Suspense,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BiLogOut } from "react-icons/bi";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import imgMenu from "../assets/image-menu.jpg";
import logo from "../assets/logo2.png";
import { keycloak } from "../keycloak";
import { indexPathState } from "../store";
import Loader from "./Loader";
import NavBar from "./Navbar";
import { Sidebar } from "./Sidebar";

type ComponentsType = {
  [key: string]: LazyExoticComponent<() => JSX.Element>;
};

const RoutesList = () => {
  const NewCustomerDetails = lazy(() => import("../pages/CustomerDetail"));
  const ComponentsReact: ComponentsType = {
    Techla: lazy(() => import("../pages/Techla")),
    Dashboard: lazy(() => import("../pages/Dashboard")),
    Customers: lazy(() => import("../pages/Customers")),
  };

  const routes = useMemo(
    () => [
      { id: 0, title: "Dashboard", icon: <InsertChartOutlinedIcon /> },
      { id: 1, title: "Customers", icon: <GroupOutlinedIcon /> },
      { id: 2, title: "Techla", icon: <AdfScannerOutlinedIcon /> },
    ],
    []
  );
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [selectedIndex, setSelectedIndex] = useRecoilState<number | null>(
    indexPathState
  );

  const setIndexPath = useSetRecoilState(indexPathState);
  useEffect(() => {
    const currentPath = routes.find(
      (item) => item.title.toLowerCase() === location.pathname.replace("/", "")
    );
    if (currentPath === undefined) {
      setIndexPath(1);
    } else {
      const previousPath = currentPath.id;
      setIndexPath(previousPath);
    }
  }, [location.pathname, routes, setIndexPath]);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleListItemClick = (index: number | null = 0) => {
    setSelectedIndex(index);
  };

  const logout = () => {
    keycloak.logout();
  };

  return (
    <Box className="flex w-full">
      <Sidebar />

      <Box
        className="flex justify-center w-full p-0 lg:px-4 lg:pb-4
          bg-[#F5F5F5] h-screen overflow-x-auto"
      >
        <Box className="w-[100%] flex flex-col">
          <Box className="hidden lg:block justify-end">
            <NavBar />
          </Box>
          <Box
            sx={{ backgroundImage: `url(${imgMenu})` }}
            className="h-fit w-full flex lg:hidden bg-no-repeat bg-center sticky justify-between"
          >
            <Box
              className={`ml-2 flex items-center w-full shadow-lg
                `}
            >
              <span className="text-[2rem]" onClick={handleClick}>
                {open ? (
                  <CloseIcon fontSize="medium" className="text-zinc-50" />
                ) : (
                  <MenuIcon fontSize="medium" className="text-zinc-50" />
                )}
              </span>

              <Box className="flex w-full justify-center p-2">
                <img
                  src={logo}
                  width="30px"
                  height="30px"
                  className="text-white flex justify-center font-bold"
                  alt="logo"
                />
              </Box>
            </Box>

            <Box className="mt-2 mx-2 flex lg:hidden">
              <IconButton
                sx={{ color: "whitesmoke" }}
                aria-label=""
                onClick={() => {
                  logout();
                }}
              >
                <BiLogOut className="icon" />
              </IconButton>
            </Box>
          </Box>

          <Box
            sx={{ backgroundImage: `url(${imgMenu})` }}
            className={` flex-col block lg:hidden h-fit w-full ${
              open ? "block" : "hidden"
            }`}
          >
            <List
              component="nav"
              aria-labelledby="nested-list-subheader"
              className="justify-center m-0 rounded-lg w-full"
            >
              {routes.map((route) =>
                selectedIndex === route.id ? (
                  <ListItem key={route.id}>
                    <ListItemButton
                      selected={selectedIndex === route.id}
                      onClick={() => handleListItemClick(route.id)}
                    >
                      <Box className="flex justify-center mx-4">
                        <ListItemIcon
                          className={`
                      ${
                        selectedIndex === route.id
                          ? "text-black"
                          : "text-slate-100"
                      }`}
                          sx={{
                            minWidth: 0,
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: "5px",
                          }}
                        >
                          {route.icon}
                        </ListItemIcon>
                      </Box>
                      <ListItemText
                        className={`
                    
                      w-32 uppercase font-bold text-lg tracking-widest
                    ${
                      selectedIndex === route.id
                        ? "text-black"
                        : "text-slate-100"
                    }`}
                        primary={route.title}
                      />
                    </ListItemButton>
                  </ListItem>
                ) : (
                  <ListItem key={route.id} className="w-full">
                    <Link to={route.title.toLowerCase()} className="w-full">
                      <ListItemButton
                        selected={selectedIndex === route.id}
                        onClick={() => handleListItemClick(route.id)}
                        className="w-full"
                      >
                        <Box className="flex justify-center mx-4">
                          <ListItemIcon
                            className={`
                      ${
                        selectedIndex === route.id
                          ? "text-black"
                          : "text-slate-100"
                      }`}
                            sx={{
                              minWidth: 0,
                              display: "flex",
                              justifyContent: "center",
                              borderRadius: "5px",
                            }}
                          >
                            {route.icon}
                          </ListItemIcon>
                        </Box>
                        <ListItemText
                          className={`
                    
                      w-32 uppercase font-bold text-lg tracking-widest
                    ${
                      selectedIndex === route.id
                        ? "text-black"
                        : "text-slate-100"
                    }`}
                          primary={route.title}
                        />
                      </ListItemButton>
                    </Link>
                  </ListItem>
                )
              )}
            </List>
          </Box>

          <Routes>
            {routes.map((route) => (
              <Route
                shouldRevalidate={({ currentUrl }) => {
                  // only revalidate if the submission originates from
                  // the `/meal-plans/new` route.
                  return currentUrl.pathname !== route.title.toLowerCase();
                }}
                key={route.id}
                path={route.title.toLowerCase()}
                element={
                  <Suspense fallback={<Loader />}>
                    {createElement(ComponentsReact[route.title])}
                  </Suspense>
                }
              />
            ))}
            <Route
              path="/"
              element={<Navigate to={routes[0].title.toLowerCase()} />}
            />
            <Route
              path="/customer/:id"
              element={
                <Suspense fallback={<Loader />}>
                  <NewCustomerDetails />
                </Suspense>
              }
            />
          </Routes>
        </Box>
      </Box>
    </Box>
  );
};

export default RoutesList;
