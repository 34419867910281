import { selector } from "recoil";
import { getCustomers } from "../api/getCustomers";

const handleGetCustomers = () => {
  return new Promise<any[]>((resolve, reject) => {
    getCustomers()
      .then((customers) => {
        console.warn("charge customer");
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const customersDataState = selector({
  key: "customersState",
  get: async () => await handleGetCustomers(),
});
